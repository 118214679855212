/* You can add global styles to this file, and also import other style files */
@import './assets/styles/colors';
@import './assets/styles/fonts';
@import './assets/styles/custom-primeng';

html,
body {
  height: 100%;
}

body {
  font-family: "Inter";
  font-size: 15px;
  margin: 0;
  padding: 0;
  width: 100%;
}

input,
textarea,
select,
button {
  font-family: "Inter" !important;
  border-radius: 7px !important;
  font-size: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter" !important;
}

input {
  border: solid 1px #c9c9c9;
  outline: rgb(255, 255, 11) !important;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #e6e6e6;
  padding: 0;
}

/* SCROLL BAR */
::-webkit-scrollbar {
  width: 10px !important;
  height: 10px;
  cursor: move;
  /* fallback if grab cursor is unsupported */
}

@media (max-width: 800px) {
  ::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
    cursor: move;
  }
}

input.ng-invalid.ng-touched {
  border: solid 1px rgba(red, 0.7) !important;
}

textarea.ng-invalid.ng-touched {
  border: solid 1px rgba(red, 0.7) !important;
}

.p-dialog-title{
  font-size: 17px !important;
}