#toast-container {
    z-index: 999999999 !important;
}

.btn-primary {
    background: linear-gradient(88deg, #943DFF -1.42%, #7E16FF 106.76%)
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.p-multiselect.ng-invalid.ng-touched.ng-focus {
    border: rgba(red, 0.7) !important;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
    background: #28253d;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.mt-10 {
    margin-top: 90px;
}


.hvr-underline-from-left {
    &::before {
        background: $color-light-green;
    }
}

.card {
    padding: 20px;
    background: white;
    border-color: white !important;
    border-radius: 8px !important;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
}

button {
    cursor: pointer;
}

.p-toast {
    opacity: 1;
    border-radius: 0px !important;
    padding: 5px 1px !important;
}

.p-toast-summary {
    display: none;
}

.p-toast-message,
.p-toast-message-content {
    padding: 3px 10px 6px 10px !important;
    border-radius: 0px !important;
}

.p-toast-message-icon {
    font-size: 14px !important;
    margin-top: 10px;

    svg {
        width: 20px;
        height: 20px;
    }
}

.remove-destination {

    .p-dialog,
    .p-dynamic-dialog,
    .p-dialog-content {
        border-radius: 7px !important;
    }
}

p-dropdown.ng-invalid.ng-touched:not(form)>div {
    border: 1px solid rgba(red, 0.7);
}