$color-light-green: rgba(42, 157, 244, 1);
$color-semi-transparent: #EFFFFD;
$color-dark-blue: #050229;
$color-black: #0F1010;
$color-black-bg: linear-gradient(180deg, #131414 0%, #0B0C0B 100%);

/* COLOR NEUTRAL 
$color-neutral-50: #f7f8f9;
$color-neutral-100: #eff1f4;
$color-neutral-200: #d8dce3;
$color-neutral-300: #c0c7d2;
$color-neutral-400: #a8b2c1;
$color-neutral-500: #6474BB;
$color-neutral-600: #4b5768;
$color-neutral-700: #323a46;
$color-neutral-800: #191d23;
$color-neutral-900: #0d0f11;*/

/* COLOR PRIMARY 
$color-primary-50: #FAF5FF;
$color-primary-100: #F4ECFF;
$color-primary-200: #E4CFFF;
$color-primary-300: #D4B1FF;
$color-primary-400: #C494FF;
$color-primary-500: #943DFF;
$color-primary-600: #7E16FF;
$color-primary-700: #5900C6;
$color-primary-800: #350077;
$color-primary-900: #120027;*/

/* COLOR SECONDARY 
$color-secondary-50: #fefaed;
$color-secondary-100: #fdf0c8;
$color-secondary-200: #fbe7a3;
$color-secondary-300: #f9dd7e;
$color-secondary-400: #f8d359;
$color-secondary-500: #f7ce46;
$color-secondary-600: ;
$color-secondary-700: ;
$color-secondary-800: ;
$color-secondary-900: ;*/

/* COLOR SUCCESS 
$color-success-50: ;
$color-success-100: ;
$color-success-200: ;
$color-success-300: ;
$color-success-400: ;
$color-success-500: ;
$color-success-600: ;
$color-success-700: ;
$color-success-800: ;
$color-success-900: ;*/

